import Vue from 'vue';
import router from '@/router/index';

export default {
    /**
     * Get list of Switch Merchants
     */
    async getMerchantList() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/listMerchants`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/merchant/listMerchants';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * merchantSize - size of merchant list
                 * merchants - JSON array of merchant objects
                 * merchant object contains:
                 * lastUpdated - datestring; date the merchant is last updated (yyyy-MM-dd HH:mm:ss.S)
                 * isMultipleBiller - boolean
                 * merchantId - string
                 * name - string
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get list of Filtered Merchants
     */
    async getUserMerchants() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/listUserMerchants`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/merchant/listUserMerchants';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {};

        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    /**
     * Get total amounts and transaction count of transactions per day for Merchant Users
     */
    async getMerchantTransactionsTotal(limit = 10, offset = 0, merchantId) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/dashboard/merchantTotals`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/dashboard/merchantTotals';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset,
            pageSize: limit,
            merchantID: merchantId,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * merchantTotals - JSON array of transaction objects
                 * transaction object contains:
                 * businessDate - datestring; date the transactions are transacted (yyyy-MM-dd HH:mm:ss.S)
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * dataName - string; name to be displayed for the data in the chart
                 * id - int; transaction object identifier
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 * merchantName - string
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get total amounts and transaction count of transactions per day for Admin Users
     */
    async getTransactionsTotal(limit = 10, offset = 0) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/dashboard/dashboardTotals`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/dashboard/dashboardTotals';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset,
            pageSize: limit,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * dashboardTotals - JSON array of transaction objects
                 * transaction object contains:
                 * businessDate - datestring; date the transactions are transacted (yyyy-MM-dd HH:mm:ss.S)
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * dataName - string; name to be displayed for the data in the chart
                 * id - int; transaction object identifier
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 * countAverage - decimal; average of transactionCount
                 * amountAverage - decimal; average of transactionAmount
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get total transaction totals per type of the day
     */
    async getTransactionsTotalPerType() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/dashboard/transactionTotalsPerType`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/dashboard/transactionTotalsPerType';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string;
                 * statusCode string; http status code
                 * transactionTotalsPerType - JSON object containing:
                 * EXPIRED - JSON object containing:
                 * percentage: double; number of percentage of expired transactions/total transactions of the day
                 * count: int; number of expired transactions of the day
                 * PAID - JSON object containing:
                 * percentage: double; number of percentage of paid transactions/total transactions of the day
                 * count: int; number of paid transactions of the day
                 * PENDING - JSON object containing:
                 * percentage: double; number of percentage of pending transactions/total transactions of the day
                 * count: int; number of pending transactions of the day
                 * POSTED - JSON object containing:
                 * percentage: double; number of percentage of posted transactions/total transactions of the day
                 * count: int; number of posted transactions of the day
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get merchant leaderboard for today
     */
    async getTransactionsLeaderboard() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/dashboard/merchantLeaderboard`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/dashboard/merchantLeaderboard';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * merchantLeaderboard - JSON array of merchant objects
                 * merchant object contains:
                 * name - string; merchantId
                 * lastUpdated - datestring; date the transaction object is last updated (yyyy-MM-dd HH:mm.ss.S)
                 * transactionAmount - int; total amount computed from the list of transactions per day
                 * transactionCount - int; total transaction count computed from the list of transactions per day
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get list of transactions
     */
    async getTransactionList(limit = 10, offset = 0, merchantId) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/list`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/transaction/list';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset,
            pageSize: limit,
            merchantID: merchantId,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Get details of a specific transaction
     */
    async getTransactionDetails(transactionId) {

    },

    async filterTransactionV2Csv(parameters) {
        // Use when CORS issue is resolved, directly communicate to server without proxy
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/searchV2Csv`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            // Use when CORS issue persists, and use proxy
            url = 'switch/transaction/searchV2Csv';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
            responseType: 'blob',
            timeout: 120000,
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            merchantID: parameters.merchantID,
            payID: parameters.payID,
            merchantReferenceNo: parameters.merchantReferenceNo,
            paymentStatus: parameters.paymentStatus,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status === 200) {
                const blob = new Blob([response.data], {type: response.data.type});
                const wUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = wUrl;
                link.setAttribute('download', 'transactions.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(wUrl);
                return wUrl;
            }
            if (response?.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            console.log(error);
            return {error};
        }
    },

    async filterTransactionV2(parameters) {
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/searchV2`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/transaction/searchV2';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            merchantID: parameters.merchantID,
            payID: parameters.payID,
            merchantReferenceNo: parameters.merchantReferenceNo,
            paymentStatus: parameters.paymentStatus,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status === 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error getting transaction list.'};
        } catch (error) {
            console.log(error);
            return {error};
        }
    },

    /**
     * Filter list of transactions based on the parameters
     * parameters: JSON object containing:
     * offset: int or empty string;
     * limit: int or empty string;
     * merchantID: string or empty string;
     * payID: string or empty string;
     * merchantReferenceNo: string or empty string;
     * paymentStatus: string or empty string;
     * dateFrom: datestring or empty string;
     * dateTo: datestring or empty string;
     */
    async filterTransaction(parameters) {
        /** Parameters example composition
         * const parameters = {
        offset: 0,
        limit: 10,
        merchantID: '',
        payID: '',
        merchantReferenceNo: '',
        paymentStatus: '', //'PAID', 'PENDING', 'POSTED', 'EXPIRED', 'STIP', 'FOR VALIDATION'
        dateFrom: '',
        dateTo: ''
      }
         */

        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/search`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/transaction/search';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            merchantID: parameters.merchantID,
            payID: parameters.payID,
            merchantReferenceNo: parameters.merchantReferenceNo,
            paymentStatus: parameters.paymentStatus,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Allowed roles to use function: admin
     * offset: int; indicates which index the list starts
     * limit: int; number of item to get from list
     * dateFrom: datestring; yyyy-mm-dd
     * dateTo: datestring; yyyy-mm-dd
     */
    async listPaymentReports(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/report/listPaymentReports`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/report/listPaymentReports';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            merchantID: parameters.merchantID,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http code
                 * paymentReports - JSON array of report objects
                 * report object contains:
                 * outputFilename - string; report file name
                 * dateCreated - datestring; date the report is created; yyyy-MM-dd hh:mm:ss.s
                 * id - int; report identifier
                 * completed - boolean; identifies if the report is complete
                 * transactionDate - datestring; date the transaction report is transacted; yyyy-MM-dd hh:mm:ss.s
                 * outputFolder - string; folder where report file is located
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * Allowed roles to use function: admin, merchantadmin, merchantuser
     * offset: int; indicates which index the list starts
     * limit: int; number of item to get from list
     * dateFrom: datestring; yyyy-mm-dd
     * dateTo: datestring; yyyy-mm-dd
     */
    async listMerchantTransactionReports(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/report/listMerchantReports`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/report/listMerchantReports';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: parameters.offset,
            pageSize: parameters.limit,
            dateFrom: parameters.dateFrom,
            dateTo: parameters.dateTo,
            merchantID: parameters.merchantID,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http code
                 * merchantTransactionalReports - JSON array of report objects
                 * report object contains:
                 * outputFilename - string; report file name
                 * dateCreated - datestring; date the report is created; hh:mm:ss.s
                 * merchant - string; merchantid
                 * id - int; report identifier
                 * completed - boolean; identifies if the report is complete
                 * transactionDate - datestring; date the transaction report is transacted; hh:mm:ss.s
                 * outputFolder - string; folder where report file is located
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    /**
     * `Download a specific report
     * @param {*} filePath string; outputFolder
     * @param {*} fileName string; outputFilename
     * @param {*} reportType string; payment-reports or merchant-reports
     */
    async downloadReport(filePath, fileName, reportType) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/report/download`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/report/download';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
            responseType: 'blob',
            timeout: 120000,
        };
        let folder = '';
        if (reportType === 'merchant-reports') {
            folder = `${process.env.VUE_APP_SWITCH_MERCHANT_REPORT_PATH}`;
        } else if (reportType === 'payment-reports') {
            folder = `${process.env.VUE_APP_SWITCH_PAYMENT_REPORT_PATH}`;
        }
        const body = {
            // fileLocation: `${folder}/${filePath}${fileName}`
            // fileLocation: `${filePath}/${fileName}`
            fileLocation: `${fileName}`,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    async downloadCsv() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/exportAdjustments`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/merchant/exportAdjustments';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
            responseType: 'blob',
            timeout: 120000,
        };

        const body = {};
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'fees.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    async getAdjustments(offset, limit, parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/listAdjustments`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/merchant/listAdjustments';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            pageNo: offset.toString(),
            pageSize: limit.toString(),
            adjustmentId: parameters.adjustmentId ?? '',
            merchantId: parameters.merchant ? parameters.merchant.merchantId ?? '' : '',
            productName: parameters.productName ?? '',
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    async getMerchantListNew() {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/listMerchants`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/merchant/listMerchants';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        try {
            const response = await Vue.axios.post(url, {}, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * merchantSize - size of merchant list
                 * merchants - JSON array of merchant objects
                 * merchant object contains:
                 * lastUpdated - datestring; date the merchant is last updated (yyyy-MM-dd HH:mm:ss.S)
                 * isMultipleBiller - boolean
                 * merchantId - string
                 * name - string
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    async createAdjustment(formData) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/createAdjustment`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/merchant/createAdjustment';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'application/json',
            },
        };
        const body = {
            merchant: formData.merchant.id,
            productName: formData.productName,
            productCode: formData.productCode,
            applicationDate: formData.applicationDate,
            percentAdjustment: formData.percentAdjustment,
            fixedAdjustment: formData.fixedAdjustment,
            minimumAmount: formData.minimumAmount,
            maximumAmount: formData.maximumAmount,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error creating adjustment record.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    async updateAdjustment(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/merchant/editAdjustment`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/merchant/editAdjustment';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            adjustmentId: parameters.id,
            merchant: parameters.merchant.merchantId,
            productName: parameters.productName,
            productCode: parameters.productCode,
            applicationDate: parameters.applicationDate,
            percentAdjustment: parameters.percentAdjustment,
            fixedAdjustment: parameters.fixedAdjustment,
            minimumAmount: parameters.minimumAmount,
            maximumAmount: parameters.maximumAmount,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error updating adjustment record.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    async sendSale(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/sendSale`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/transaction/sendSale';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            payconnectReference: parameters.payconnectReference,
            merchantId: parameters.merchantId,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error in Send Sale.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    async sendReversal(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/reversal`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/transaction/sendReversal';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            payconnectReference: parameters.payconnectReference,
            merchantId: parameters.merchantId,
            reasonCode: 'TIMED-OUT',
            reasonDescription: 'Connection Timeout',
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                return response.data;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error in Send Reversal.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    getError(error) {
        if (error && error.response && error.response.data) {
            console.log(error.response);
            if (error.response.data.message) {
                return {error: `${error.response.data.message}`};
            }
            if (error.response.data.error) {
                return {error: `${error.response.data.error}`};
            }
            return {error: `${error.response.data}`};
        }
        return {error: `${error}`};
    },

    async updateTransactionStatus(parameters) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/updateStatus`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/transaction/updateStatus';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            payconnectReference: parameters.payconnectReference,
            status: parameters.status,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting list of merchants.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },

    async uploadFile(formData) {
        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/transaction/uploadTransactionCsv`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = '/switch/transaction/uploadTransactionCsv';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
            timeout: 120000,
        };
        try {
            const response = await Vue.axios.post(url, formData, config);
            console.log(response);
            if (response && response.data && response.status == 200) {
                const blob = new Blob([response.data], {type: response.data.type});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'generatedTransactions.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
                return url;
            }
            if (response && response.data && response.data.message) {
                return {error: response.data.message};
            }
            return {error: 'Sorry. Error in Send Sale.'};
        } catch (error) {
            console.log(error);
            return this.getError(error);
        }
    },

    async filterStipTransaction(parameters) {
        /** Parameters example composition
         * const parameters = {
        offset: 0,
        limit: 10,
        merchantID: '',
        payID: '',
        merchantReferenceNo: '',
        paymentStatus: '', //'PAID', 'PENDING', 'POSTED', 'EXPIRED', 'STIP', 'FOR VALIDATION'
        dateFrom: '',
        dateTo: ''
      }
         */

        /** Use when CORS issue is resolved, directly communicate to server without proxy */
        let url = `${process.env.VUE_APP_SWITCH_DASHBOARD_URL}/stip/list`;
        if (process.env.VUE_APP_IS_PROXY === 'true') {
            /** Use when CORS issue persists, and use proxy */
            url = 'switch/stip/list';
        }
        const config = {
            headers: {
                Authorization: `${router.app.$keycloak.token}`,
            },
        };
        const body = {
            transactionId: parameters.transactionId,
        };
        try {
            const response = await Vue.axios.post(url, body, config);
            if (response && response.data && response.status == 200) {
                /*
                 * Response.data - JSON object and contains:
                 * message - string
                 * statusCode - string; http cpde
                 * transactions - JSON array of transaction objects
                 * transaction object contains:
                 * expiryDate - datestring; date the transaction will expire
                 * amount - decimal; transaction amount
                 * serviceCharge - decimal; transaction service charge
                 * dateCreated - datestring; date the transaction is created
                 * merchantReferenceNo - string; merchant reference number
                 * merchant - string; merchantId
                 * id - int; transaction identifier
                 * paymentStatus - string; status of transaction
                 * sevenPayID - string; payconnect reference number
                 */
                return response.data;
            }
            if (response && response.data && response.data.message) {
                /*
                 * Response.data JSON object and contains:
                 * statusCode - string; http status code
                 * message - string
                 * expired - boolean
                 */
                return {error: response.data.message};
            }
            /**
             * Return general error
             */
            return {error: 'Sorry. Error getting details of merchant.'};
        } catch (error) {
            /**
             * Exception error
             */
            console.log(error);
            return {error};
        }
    },
    /** How to use the APIs above
     * Example:
     * import API from '@/api/API'
     * API.getMerchantList().then(response => {
      console.log(response)
    })
     * or------------------------
     * const merchantList = await API.getMerchantList()
     * The function where the above statement should be declared as async then
     */
};
